<template>
  <BulleDeco positionY="-150" />
  <div class="deco_bulle_bg justify-center">
    <h1 class="line-75">
      Une plateforme pour rechercher, comparer et analyser rapidement
    </h1>
  </div>
  <div class="section flex-col">
    <!--SECTION 1  -->
    <div class="section_child grid grid-cols-1 lg:grid-cols-2">
      <div
        class="
          pt-6
          pr-6
          pb-6
          pl-6
          lg:pt-14 lg:pr-14 lg:pb-14 lg:pl-14
          flex-col
          justify-center
          items-center
        "
      >
        <h2>Rechercher des biens immobiliers</h2>
        <p>
          Accédez à l'ensemble des transactions immobilières depuis 2012.
          Affinez votre recherche grâce à l'approche multicritères
          (géolocalisation, surfaces, type de bien, …) <br />
          Epurez les résultats à l’aide de nos différents filtres (Intelligent
          Data avec dispersion statistique pré définie)
        </p>
      </div>
      <div class="yellow flex justify-center items-center">
        <img width="430" :src="require('../assets/images/Group 832.png')" />
      </div>
    </div>

    <!-- SECTION 2 -->
    <div class="section_child grid grid-cols-1 lg:grid-cols-2">
      <div class="blue h-full flex justify-center items-center">
        <img width="580" :src="require('../assets/images/Group 47.svg')" />
      </div>
      <div class="pt-6 pr-6 pb-6 pl-6 lg:pt-14 lg:pr-14 lg:pb-14 lg:pl-14">
        <h2>Comparer vos recherches, vos biens dans chaque ville</h2>
        <p>
          Exploitez plusieurs dizaines de millions de données pour enrichir vos
          rapports et argumenter votre analyse.<br />Visualiser les ventes sur
          une carte (Google Street View, détails de la transaction sur des
          points dynamiques, neutralisation de points non représentatifs …)
        </p>
      </div>
    </div>

    <!-- SECTION 3 -->
    <div class="section_child grid grid-cols-1 lg:grid-cols-2">
      <div
        class="
          pt-6
          pr-6
          pb-6
          pl-6
          lg:pt-14 lg:pr-14 lg:pb-14 lg:pl-14
          flex-col
          justify-center
          items-center
        "
      >
        <h2>Analyser avec précision grâce à notre data motor</h2>
        <p>
          Visualisez les ventes réalisées dans un secteur déterminé à l'aide de
          graphiques : nuages de points, courbe de tendance, histogrammes,
          corrélation prix/m², ... <br />Positionnez vos biens grâce à nos
          outils statistiques et démographiques adaptés aux professionnels.
        </p>
      </div>
      <div class="pink flex justify-center items-center">
        <img width="630" :src="require('../assets/images/ill.png')" />
      </div>
    </div>

    <!--SECTION 4  -->
    <div class="section_child grid grid-cols-1 lg:grid-cols-2">
      <div class="purpe flex justify-center items-center">
        <img width="660" :src="require('../assets/images/Group 833.png')" />
      </div>
      <div
        class="
          pt-6
          pr-6
          pb-6
          pl-6
          lg:pt-14 lg:pr-14 lg:pb-14 lg:pl-14
          flex-col
          justify-center
          items-center
        "
      >
        <h2>Rapports personnalisés et téléchargeables</h2>
        <p>
          Étayez vos rapports en vous appuyant sur des données vérifiées et
          vérifiables. Exportez les graphiques souhaitez et les données de
          transactions pour illustrer vos rapports. Optimisez vos rendus, vos
          analyses à l’aide de nos fiches marché détaillées et insérez votre
          logo.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import BulleDeco from "../components/public/B2b6.bulle_deco.vue";

export default defineComponent({
  name: "B2b4",
  components: {
    BulleDeco,
  },
});
</script>

<style scoped>
h1:nth-child(1) {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -1px;
  text-align: center;
  color: #363660;
}
h2 {
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -1px;
  text-align: left;
  color: #363660;
  margin-bottom: 20px;
}

p {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
}
.deco_bulle_bg {
  display: flex;
  width: 100;
  min-height: 300px;
  background-color: #f6f7fb;
  padding: 0 25vw 0 25vw;
  align-items: center;
}

.section {
  position: relative;
  width: 100%;
  background: white;
}

.section_child {
  position: relative;
  min-height: 560px;
}

.child1 {
  /* padding: 142px 117px 141px 108px; */
}

.yellow {
  background-color: #f7ac25;
}

.blue {
  background-color: #4cbfde;
}

.pink {
  background-color: #e94a73;
}
.purpe {
  background-color: #363660;
}
.line-75 {
  line-height: 1.35 !important;
}
</style>